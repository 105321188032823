@import '../style/include.scss';

.bg-btn{
    
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 2;
    padding:10px;
    cursor: pointer;
    svg{
        width:40px;
        height:40px;
    }
    border-width: 0;
    
    @extend %back-fill;
}
.bg-form{
    
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 2;
    padding:10px;
    svg{
        width:40px;
        height:40px;
    }
    border-width: 0;
    
    @extend %back-fill;
}