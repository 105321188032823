@import '../style/include.scss';

.room{
    position: relative;

    .room-layout{

        li{
            position: absolute;
            display:flex;
            justify-content: center;
            align-items: center;
            transition: width $trans, height $trans, left $trans, top $trans, opacity $trans, visibility $trans;

            &.hide{
                opacity: 0;
                visibility: hidden;
            }

            span{
                display: inline-block;
                @extend %back-foreground-fill;
            }

            video{
                background: none;
                &.ready{
                    transition: width $trans, height $trans, left $trans, top $trans;
                }
            }
        }    

    }

    .room-layout-empty{
        display: flex;
        flex:1;
        justify-content: center;
        align-items: center;
        >div{
            
            font-size: 18px;
            font-weight: 700;
            display: inline;
            border-radius: 10px;
            @extend %back-foreground-fill;
        }

        opacity:0;
        transition: opacity $trans;
    }

    .disconnect-btn{
        
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 2;
        padding:10px;
        cursor: pointer;
        svg{
            width:40px;
            height:40px;
        }
        border-width: 0;
        
        @extend %back-fill;
    }

    &.room-empty{
        .room-layout-empty{
            opacity:1;
        }
    }
}