@import '../style/include.scss';

.room-join-container{
    display: flex;
    flex-direction: column;
    flex:1;
    justify-content: center;
    >div{
        padding:60px 60px 30px 60px;
        max-width: 550px;
        @extend %back-fill;
    }
}