html,body,#root{
    position: relative;
    width:100%;
    height:100%;
    margin:0;
    padding:0;
    overflow: hidden;
}

#root{
    display:flex;
    flex-direction: column;
}