$trans:0.4s ease-in-out;
$transMd:1s ease-in-out;
$transLg:3s ease-in-out;

%back-fill{
    background:rgba($color: #000000, $alpha: 0.5);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    padding: 40px;
    color:#fff;
}

%back-foreground-fill{
    @extend %back-fill;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    svg{
        fill:#fff;
    }
}

%abs-fill{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
}