@import '../style/include.scss';
.room-bg{
    @extend %abs-fill;

    img{
        @extend %abs-fill;
        object-fit: cover;

        opacity: 0;
        filter:blur(10px);
        transition:opacity $trans, filter $transMd;
        &.loaded{
            opacity: 1;
            filter:blur(0);
        }
    }
}
.image-attribution{
    position:absolute;
    right:10px;
    bottom:10px;
    font-size:11px;
    opacity: 0.7;
    z-index:10;

    @extend %back-fill;
    padding:10px;
    color:#fff;
    a{
        color:#fff !important;
        font-weight: bold;
    }
}